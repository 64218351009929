<template>
    <div class="footer footer-main py-5">
        <div class="text-center container-xl d-flex flex-column flex-lg-row justify-content-around align-items-center">
            <a 
                :href="$store.state.file_url" 
                target="_blank" 
                class="mb-4 mb-lg-0 text-decoration-none text-secondary"
            >
                Játékszabályzat
            </a>
            <a 
                :href="$store.state.privacy_url" 
                target="_blank" 
                class="mb-4 mb-lg-0 text-decoration-none text-secondary"
            >
                Adatkezelési tájékoztató
            </a>
            <a class="fw-bold text-secondary mb-4 mb-lg-0 text-decoration-none" href="pdf/cookie-policy.pdf" target="_blank">Cookie tájékoztató</a>
            <p class="fw-bold text-secondary mb-4 mb-lg-0 text-decoration-none" @click="setCookies = true" style="cursor:pointer;">Cookie beállítások</p>
            <a class="text-secondary text-decoration-none" href="pdf/gyik.pdf" target="_blank">GYIK</a>
            <a href="#" v-scroll-to="'#hero'" class="scroll-up mt-4 mt-md-0"></a>
        </div>  
        <p class="text-center py-5 my-5"><i>A képek illusztrációk</i></p>      
        <div>            
            <AgeGate />
            <CookieBannerComp :cookieShow="setCookies" @setDefault="setCookies = false"
            class="w-100  d-flex align-items-center justify-content-center justify-content-md-start mb-0"/>            
        </div>
    </div>
</template>


<script>
import CookieBannerComp from '@/plugins/cookiebanner/CookieBannerComp.vue'
import AgeGate from '@/components/AgeGateComp.vue'

export default {
    data(){
      return{
        setCookies: false
      }
    },
    components: {
        CookieBannerComp,
        AgeGate        
    }
}
</script>