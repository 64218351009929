<template>
    <div id="ageGateCookie" class="ageGateCookie bg-red" v-if="needAgeGate">
        
            <!-- AGE GATE -->
            <div class="container-xl">
                <div class="ageGateCookie__ageGate row">
                    <div class="col-12 col-lg-10 offset-lg-1">      
                        <img src="@/assets/imgs/pombar.svg" alt="logo" class="logo mb-5 pb-5"  />              
                        <div class="ageGate__title text-white fw-700 ">Elmúlt már 18 éves?</div>
                        <p class="fs-16 fs-md-18 text-white mb-5">Kérjük, a belépéshez adja meg születési dátumát!</p>
                        <div class="ageGate__form">
                            <input v-mask="'####'" ref="ageGateYear" v-model="ageGateInputYear" placeholder="ÉÉÉÉ" type="text" />
                            <input v-mask="'##'" ref="ageGateMonth" v-model="ageGateInputMonth" placeholder="HH" type="text" />
                            <input v-mask="'##'" ref="ageGateDay" v-model="ageGateInputDay" placeholder="NN" type="text" />
                        </div>
                        <div class="col-lg-8 offset-lg-2 my-5 pt-5">
                            <CheckBox
                                class="mt-2"
                                name="acknowledge"
                                v-model="acknowledge"
                                :checked="acknowledge"
                                rules="required|acceptConditions"   
                            >
                                <p class="mb-0 font-secondary text-white text-start"> 
                                    <span>Az <a href="pdf/age-gate-data-policy.pdf"  target="_blank" class="text-white text-underline fw-bold">Adatkezelési tájékoztatót</a> megismertem, az abban foglaltakat elfogadom.</span>                                            
                                    <span class="text-white fw-bold">*</span>    
                                </p>
                            </CheckBox>
                            <CheckBox
                                class="mt-2"
                                name="policy"
                                v-model="policy"
                                :checked="policy"
                                rules="required|acceptConditions"   
                            >
                                <p class="mb-0 font-secondary text-white text-start"> 
                                    A promócióban való részvétel és a promóciós weboldal használata családoknak ajánlott, felnőtt felügyelete mellett.                                          
                                    <span class="text-white fw-bold">*</span>    
                                </p>
                            </CheckBox>
                        </div>
                        <div class="d-flex flex-column align-items-center">
                            <div class="error-msg" v-if="ageGateError">{{ageGateError}}</div>
                            <!--<div class="divider my-8"></div>-->
                            <button type="button" class="btn btn-secondary mt-5" @click="checkAgeGate()">Belépek</button>
                        </div>
                    </div>
                </div>   
            </div>
          <!--  <footer class="pt-4 fs-14 text-white text-center">
                A belépéssel elfogadod az <a :href="$store.state.privacy_url"  target="_blank" class="text-white text-underline fw-bold">Adatvédelmi szabályzatot</a>.
            </footer>       -->              
        
    </div>
</template>

<script>
import {mask} from 'vue-the-mask'
import CheckBox from '@/components/base/CheckBox.vue'

export default {
    components:{
        CheckBox
    },
    data(){
        return{
            ageGateInputYear: null,
            ageGateInputMonth :null,
            ageGateInputDay: null,
            ageGateError: null,        
            needAgeGate:true,  
            ageGateCookieName: 'watchedAgeGate',
            policy: false,
            acknowledge: false,
            // needCookieSettings: true,
            // changeCookies: false,
            // cookies:{
            //     required: '1',
            //     marketing: '0',
            //     statistic: '0'
            // },
            expiredDate: 60 * 60 * 24 * 30             
        }
    },
    directives: {
        mask
    },
    mounted(){
      var _this = this

      if ( parseInt(this.$cookies.get(`${_this.ageGateCookieName}`)) === 1 ){          
        _this.needAgeGate = false        
      }

      if (this.needAgeGate){
          document.body.classList.add('noScroll');
      } else {
          document.body.classList.remove('noScroll');
      }
    }, 
    watch:{      
        ageGateInputYear(){
            if (this.ageGateInputYear.length === 4) {
                this.isValidYear()
            }           
        },
        ageGateInputMonth() {
            if (this.ageGateInputMonth.length === 2){
                this.isValidMonth()
            }            
        },
        ageGateInputDay(){
            this.isValidDay()
        }
    },
    methods:{
        isValidYear(){
            var _this = this
            var currentYear = new Date();
            var goodYear = parseInt(currentYear.getFullYear()) - 18;                        

            if ( parseInt(this.ageGateInputYear) > goodYear || parseInt(this.ageGateInputYear) < 1910 || this.ageGateInputYear == null || this.ageGateInputYear == '') {
                _this.$refs.ageGateYear.classList.add("error-input")    
                _this.ageGateError = "Csak 18 éven felüliek tekinthetik meg az oldalt."            
                return false
            } else {
                // console.log('valid year')
                _this.$refs.ageGateYear.classList.remove("error-input")      
                _this.ageGateError = null
                _this.$refs.ageGateMonth.focus()
                return true
            }
        },
        isValidMonth(){
            var _this = this

            if ( this.ageGateInputMonth == null || this.ageGateInputMonth == '' || parseInt(this.ageGateInputMonth) > 12 || parseInt(this.ageGateInputMonth) < 1) {
                _this.$refs.ageGateMonth.classList.add("error-input")  
                return false
            } else {
                _this.$refs.ageGateMonth.classList.remove("error-input")      
                _this.$refs.ageGateDay.focus()            
                return true
            }
        },
        isValidDay(){
            var _this = this

            if ( this.ageGateInputDay == null || this.ageGateInputDay == '' || parseInt(this.ageGateInputDay) > 31 || parseInt(this.ageGateInputDay) < 1) {                
                _this.$refs.ageGateDay.classList.add("error-input")  
                return false                
            } else {
                if ( 
                    ( 
                        parseInt(_this.ageGateInputMonth) === 4 || 
                        parseInt(_this.ageGateInputMonth) === 6 || 
                        parseInt(_this.ageGateInputMonth) === 9 || 
                        parseInt(_this.ageGateInputMonth) === 11
                    ) && parseInt(_this.aageGateInputDay == 31) ) {                        
                        //30 day month check
                        _this.$refs.ageGateDay.classList.add("error-input")  
                        return false
                } else if ( parseInt(_this.ageGateInputMonth) === 2 && parseInt(_this.aageGateInputDay) > 29){                                   
                    //february check
                    _this.$refs.ageGateDay.classList.add("error-input")  
                    return false                    
                } else {                    
                    _this.$refs.ageGateDay.classList.remove("error-input")  
                    return true
                }
            }
        },     
        isValidDate(){            

            if (this.isValidYear() && this.isValidMonth() && this.isValidDay() ){                                
                return true
            } else {
                return false
            }
            
        },
        isValidAge(){
            var _this = this

            //is every input is filled?
            if ( _this.ageGateInputYear !== null && _this.ageGateInputMonth !== null && _this.ageGateInputDay !== null) {            
                //over than 18 age             

                if (_this.isValidDate()) {
                    //every input is filled and good value
                    let birthDateY = parseInt(_this.ageGateInputYear);
                    let birthDateM = parseInt(_this.ageGateInputMonth);
                    let birthDateD = parseInt(_this.ageGateInputDay);      
                                            
                    var birthdate = new Date();
                    birthdate.setFullYear(birthDateY, birthDateM-1, birthDateD);

                    var currdate = new Date();
                    currdate.setFullYear(currdate.getFullYear() - 18);

                    // console.log('setfullyear', currdate, birthdate, (currdate - birthdate) )
                    
                    if ((currdate - birthdate) < 0){                          
                        _this.ageGateError = "Csak 18 éven felüliek tekinthetik meg az oldalt."     
                        return false;
                    } else {        
                        return true;
                    }
                }
                
            } else {
                // there is empty input field
                _this.ageGateError = "Kérjük, töltse ki az összes mezőt."

                if (_this.aageGateInputYear == null){
                    _this.$refs.ageGateYear.classList.add("error-input")                    
                }
                if (_this.ageGateInputMonth == null){
                    _this.$refs.ageGateMonth.classList.add("error-input")                    
                }
                if (_this.ageGateInputDay == null){
                    _this.$refs.ageGateDay.classList.add("error-input")                    
                }

                return false
            }
            
        },
        checkAgeGate(){
            var _this = this

            if(this.policy && this.acknowledge){
                _this.ageGateError = null; 
                // GA4 *************** //
                let btnSucc = this.isValidAge() ? 'sikeres' : 'sikertelen'
                
                this.GTtrackGA4({
                    'event' : 'agegate',
                    'category': 'agegate',
                    'action': 'tovabb_gombra_kattintas',
                    'label': btnSucc,
                    'page_type': 'agegate',
                    'button': 'tovabb',
                    'clicked_text':'Folytatom az ajánlott sütikkel',
                    'success': btnSucc,
                })

                // GA4 END *********** //

                console.log('checkAgeGate')

                if (_this.isValidAge()) {
                    console.log('checkAgeGate isValidAge')
                    _this.$cookies.set(`${_this.ageGateCookieName}`, 1, _this.expiredDate);
                    _this.needAgeGate = false
                    document.body.classList.remove('noScroll');
                } 
            }else{
                 _this.ageGateError = 'Kérjük fogadja el az összes mezőt!'; 
            }
        },
           
    }
}
</script>